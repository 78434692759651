
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$test-primary: mat-palette($mat-indigo);
$test-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$test-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$test-theme: mat-light-theme((
  color: (
    primary: $test-primary,
    accent: $test-accent,
    warn: $test-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($test-theme);

/* You can add global styles to this file, and also import other style files */
.orl-gradient-color {
  background: linear-gradient(45deg, #26a0da, #314755, #314755 150%);
  color: white
}

.orl-area--card {
    padding: 10px;
    margin: 5px;
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 15px 0px #e0e0e0, 0 2px 1px -1px #fff7f7;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Profile */
.orl-vsm-crcle-prof .orl-vsm-crcle-prof-preview {
    width: 35px;
    height: 35px;
    border-radius: 20%;
    border: 0.1rem solid white;
    background-color: white;
    -webkit-box-shadow: 0 0 100px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
}

.orl-vsm-crcle-prof .orl-vsm-crcle-prof-preview>div {
    width: 100%;
    height: 100%;
    border-radius: 20%;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: center;
}

.orl-primary-button {
  padding: 1rem 2rem 0.9rem;
  font-size: 16px;
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(45deg, #26a0da, #314755, #314755 150%);
  color: white;
  font-weight: bold;
  border: 1px solid;
  cursor: pointer;
}

.orl-submit-button {
  cursor: pointer;
  padding: 1rem 2rem 0.9rem;
  margin: 0.5rem;
  border-radius: 10px;
  border: 1px solid;
  letter-spacing: 0.1rem;
  font-weight: 600;
  background: #314755;
  color: #ffffff;
  font-size: 16px;
}

.orl-secondary-button {
  cursor: pointer;
  padding: 1rem 2rem 0.9rem;
  margin: 0.4rem;
  border-radius: 10px;
  border: 1px solid;
  letter-spacing: 0.1rem;
  font-weight: 600;
  background: #26a0da;
  color: white;
  font-size: 16px;
}

/** mat table */
.mat-table {
  width: 100%;
  overflow: auto;
}

mat-cell,
mat-footer-cell,
mat-header-cell {
  width: 200px;
  flex: none;
  justify-content: center;
}

.mat-table mat-cell:first-child {
  padding-left: 0px;
  border-left: 1px solid;
}

.mat-table mat-cell:last-child {
  padding-right: 0px;
}

.mat-table mat-header-cell:first-child {
  padding-left: 0px;
  border-left: 1px solid;
}

.mat-table mat-header-cell:last-child {
  padding-right: 0px;
}

.mat-table mat-header-cell {
  border-top: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  cursor: col-resize;
}

.mat-table mat-cell {
  border-right: 1px solid;
  border-bottom: 1px solid;
}

/** Page Loader */
.loader {
  border: 9px solid #f3f3f3;
  border-top: 9px solid #26a0da;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
